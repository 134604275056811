import { defineStore } from 'pinia'

export const useUserInfoStore = defineStore('userInfo', {

    state: () => {
        return {
            currentUserId: null,
            currentProfileId: null,
            currentProfileIndex: null,
            profiles: null,
            currentProfileHexCode: null,
            currentProfile: null,
            currentUserHexCode: null,
            annualPrice: null,
            approved: null,
            contactPerson: null,
            country: null,
            email: null,
            hasMarketwurks: null,
            orgType: null,
            marketId: null,
            name: null,
            orgName: null,
            numOfMarkets: null,
            termsOfAgreement:  null,
            withinMarketOrg: null,
            token: null,
            admin: null,
            successForm: null,
            reloadProfiles: false,
            profilesReturned: false,
            mostRecentProfileId: null,
            currentStatus: "Logging In",
            isSPCSP: null,

            masquerading: false,
            actualUserId: null, // used for masquerading
            pathBeforeMasquerading: "/users",
            profileIdBeforeMasquerading: null,
            profilesBeforeMasquerading: null,
            profileBeforeMasquerading: null,
            newProducerUserId: null,
            newUserId: null,

            marketProfileIdsForCurrentProducerProfileId: [],

            profileDeleted: false,
            profileAdded: false,
            profileDuplicated: false
        }
    },
    actions: {
    },
    persist: true
})