<template>
  <mainMenu></mainMenu>
  <router-view />
  <footerDiv></footerDiv>
</template>

<script>


import mainMenu from "@/components/MainMenu/MainMenu";
import footerDiv from "@/components/Footer/Footer";
import axios from 'axios'
import router from "@/router";
import {useUserInfoStore} from "@/stores/userInfo";
export default {
  name: 'App',
  components: {
    footerDiv,
    mainMenu
  },
  setup() {
    const userInfoStore = useUserInfoStore()
    return { userInfoStore }
  },
  methods: {
    handleHttpError(error) {
      if (error === null) throw new Error('Unrecoverable error!! Error is null!')
      if (axios.isAxiosError(error)) {
        //here we have a type guard check, error inside this if will be treated as AxiosError
        const response = error?.response
        const request = error?.request
        //const config = error?.config //here we have access the config used to make the api call (we can make a retry using this conf)

        if (error.code === 'ERR_NETWORK') {
          console.log('connection problems..')
          alert("There was a connection issue. Please make sure you are connected to the internet. If you are, please try again later.")
        } else if (error.code === 'ERR_CANCELED') {
          console.log('connection canceled..')
          alert("Connection canceled. Please try again.")
        }
        if (response) {
          //The request was made and the server responded with a status code that falls out of the range of 2xx the http status code mentioned above
          const statusCode = response?.status
          if (statusCode === 404) {
            console.log('The requested resource does not exist or has been deleted')
            alert("The requested resource does not exist or has been deleted.")
          } else if (statusCode === 401) {
            console.log('Please login to access this resource')
            //redirect user to login
            this.userInfoStore.$reset()
            router.push(process.env.VUE_APP_BASE_PATH + "/login")
          }
        } else if (request) {
          //The request was made but no response was received, `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in Node.js
          alert("The following error occurred:" + error )
        }
      }
      //Something happened in setting up the request and triggered an Error
      console.log(error.message)
    }
  }
}
</script>

<style>
#app {
  /*font-family: Roboto, Helvetica, Arial, sans-serif;*/
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
@import './assets/styles/style.less';
</style>

