import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'mdb-vue-ui-kit/css/mdb.min.css';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import axios from "axios";
import {useUserInfoStore} from "@/stores/userInfo";
import LoadScript from "vue-plugin-load-script";


/*import { MDBRadio, MDBTextarea, MDBCheckbox, MDBFile, MDBInput, MDBAccordion, MDBAccordionItem, MDBTabs, MDBTabNav, MDBTabItem, MDBTabPane, MDBTabContent} from 'mdb-vue-ui-kit';*/
import {
    MDBIcon,
    MDBInput,
    MDBContainer,
    MDBCard,
    MDBCardTitle,
    MDBCardText,
    MDBCheckbox,
    MDBBtn,
    MDBSelect,
    MDBFile,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBRadio,
    MDBAccordion,
    MDBAccordionItem,
    MDBTransition,
    MDBSpinner
} from 'mdb-vue-ui-kit';

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
let app = createApp(App)
app.component('MDBInput', MDBInput)
app.component('MDBContainer', MDBContainer)
app.component('MDBCard', MDBCard)
app.component('MDBCardTitle', MDBCardTitle)
app.component('MDBCardText', MDBCardText)
app.component('MDBCheckbox', MDBCheckbox)
app.component('MDBIcon', MDBIcon)
app.component('MDBBtn', MDBBtn)
app.component('MDBSelect', MDBSelect)
app.component('MDBFile', MDBFile)
app.component('MDBModal', MDBModal)
app.component('MDBModalHeader', MDBModalHeader)
app.component('MDBModalTitle', MDBModalTitle)
app.component('MDBModalBody', MDBModalBody)
app.component('MDBModalFooter', MDBModalFooter)
app.component('MDBRadio', MDBRadio)
app.component('MDBAccordion', MDBAccordion)
app.component('MDBAccordionItem', MDBAccordionItem)
app.component('MDBTransition', MDBTransition)
app.component('MDBSpinner', MDBSpinner)
app.use(LoadScript)



axios.interceptors.request.use((config) => {
    const userInfoStore = useUserInfoStore()
    if (userInfoStore.token) {
        config.headers["access-token"] = userInfoStore.token.token
        config.headers["token-type"] = "Bearer"
        config.headers["client"] = userInfoStore.token.client
        config.headers["uid"] = userInfoStore.email
    }
    return config
})

app
    .use(router)
    .use(pinia)
    .mount('#app')
