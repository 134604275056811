import { createWebHistory, createRouter } from "vue-router";
import {useUserInfoStore} from "@/stores/userInfo";

const routes = [
    {

        path: process.env.VUE_APP_BASE_PATH,
        name: 'Home',
        component: () =>
            import("../views/LandingPage/LandingPage.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/welcome",
        name: 'Welcome',
        component: () =>
            import("../views/LandingPage/LandingPage.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/welcome-producer",
        name: 'Welcome Producer',
        component: () =>
            import("../views/instruments/EcosystemServices/ESSLanding/ESSLanding.vue"),
        meta: { requiresAuth: true }
    }
    ,{
        path: process.env.VUE_APP_BASE_PATH + "/login",
        name: "Login",
        component: () =>
            import("../views/users/LoginView/LoginView.vue")
    },
    {
        path:  process.env.VUE_APP_BASE_PATH + "/password/reset",
        name: "PasswordReset",
        component: () =>
            import("../views/users/PasswordReset/PasswordReset.vue")
    }
    ,{
        path:  process.env.VUE_APP_BASE_PATH +  "/password/update",
        name: "PasswordUpdate",
        component: () =>
            import("../views/users/PasswordUpdate/PasswordUpdate.vue")
    }
    ,{
        path:  process.env.VUE_APP_BASE_PATH + "/users",
        name: "Users",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/users/UsersView/UsersView.vue")
    },
    {
        path:  process.env.VUE_APP_BASE_PATH + "/user/:id",
        name: "User",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/users/UserView/UserView.vue")
    },
    {
        path:  process.env.VUE_APP_BASE_PATH + "/user/:id/assign_markets",
        name: "Assign Markets To Market Org",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/users/AssignMarketsToMarketOrg/AssignMarketsToMarketOrg.vue")
    },
    {
        path:  process.env.VUE_APP_BASE_PATH + "/user/:id/assign_market_orgs",
        name: "Assign Market Orgs To Researcher",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/users/AssignMarketOrgsToResearcher/AssignMarketsOrgsToResearcher.vue")
    },
    {
        path:  process.env.VUE_APP_BASE_PATH + "/masquerade/:id",
        name: "MasqueradeView",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/users/MasqueradeView/MasqueradeView.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/register/:hex_code",
        name: "Register with hex code",
        component: () =>
            import("../views/users/RegisterView/RegisterView.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/register",
        name: "Register",
        component: () =>
            import("../views/users/RegisterView/RegisterView.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/register-success",
        name: "Register Success",
        component: () =>
            import("../views/users/RegisterSuccess/RegisterSuccess.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/new_producer",
        name: "New Producer",
        component: () =>
            import("../views/users/NewProducerView/NewProducerView.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/new_producer_success",
        name: "New Producer Success",
        component: () =>
            import("../views/users/NewProducerSuccessView/NewProducerSuccessView.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/visitor-surveys",
        name: "Visitor Surveys Data",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/VisitorSurvey/VisitorSurveyData/VisitorSurveyData.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/visitor-count/market-entry-point/:hexCode/",
        name: "Market Entry Point",
        meta: { requiresAuth: false },
        component: () =>
            import("../views/instruments/VisitorCount/VisitorCountForm/MarketEntryPoint/MarketEntryPoint.vue")
    },

    {
        path: process.env.VUE_APP_BASE_PATH + "/visitor-count/market-entry-point-date/:hexCode/",
        name: "Market Entry Point Date",
        meta: { requiresAuth: false },
        component: () =>
            import("../views/instruments/VisitorCount/VisitorCountForm/MarketEntryPointDate/MarketEntryPointDate.vue")
    },

    {
        path: process.env.VUE_APP_BASE_PATH + "/visitor-count/form",
        name: "Visitor Count Form",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/VisitorCount/VisitorCountForm/VisitorCountForm.vue")
    },

    {
        path: process.env.VUE_APP_BASE_PATH + "/vendor-application/form",
        name: "Vendor Application Form",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/VendorApplication/VendorApplicationForm/VendorApplicationForm.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/vendor-attendance/form",
        name: "Vendor Attendance Form",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/VendorAttendance/VendorAttendanceForm/VendorAttendanceForm.vue")
    },

    {
        path: process.env.VUE_APP_BASE_PATH + '/vendor-attendance/edit/:id',
        name: 'Edit Vendor Attendance',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/VendorAttendance/VendorAttendanceForm/VendorAttendanceForm.vue")
        }
    },

    {
        path: process.env.VUE_APP_BASE_PATH + "/visitor-survey-form/:hexCode",
        name: "Create Visitor Survey Hex",
        meta: { requiresAuth: false },
        component: () =>
            import("../views/instruments/VisitorSurvey/VisitorSurveyForm/VisitorSurveyForm.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/visitor-surveys/new",
        name: "Create Visitor Survey",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/VisitorSurvey/VisitorSurveyForm/VisitorSurveyForm.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/visitor-surveys/:profileHex/edit/:id',
        name: 'Edit Visitor Survey',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/VisitorSurvey/VisitorSurveyForm/VisitorSurveyForm.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/visitor-surveys/:profileHex/edit/:id',
        name: 'Edit Visitor Survey',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/VisitorSurvey/VisitorSurveyForm/VisitorSurveyForm.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/visitor-surveys/:profileHex/new/:id/success',
        name: 'Visitor Survey Created',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/VisitorSurvey/VisitorSurveyForm/VisitorSurveyNewSuccess.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/vendor-application-form/:hexCode",
        name: "Create Vendor Application Hex",
        meta: { requiresAuth: false },
        component: () =>
            import("../views/instruments/VendorApplication/VendorApplicationForm/VendorApplicationForm.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/vendor-applications/new",
        name: "Create Vendor Application",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/VendorApplication/VendorApplicationForm/VendorApplicationForm.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/vendor-applications/:profileHex/edit/:id',
        name: 'Edit Vendor Application',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/VendorApplication/VendorApplicationForm/VendorApplicationForm.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/vendor-applications/:profileHex/edit/:id',
        name: 'Edit Vendor Application',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/VendorApplication/VendorApplicationForm/VendorApplicationForm.vue")
        }
    },

    {
        path: process.env.VUE_APP_BASE_PATH + "/vendor-sales-slips-producer",
        name: "Vendor Sales Slip Producer Landing Page",
        meta: { requiresAuth: false },
        component: () =>
            import("../views/instruments/VendorsSalesSlip/VendorSalesSlipProducerLanding/VendorSalesSlipProducerLanding.vue")
    },


    {
        path: process.env.VUE_APP_BASE_PATH + "/vendor-sales-slips-form/:hexCode",
        name: "Create Vendor Sales Slip Hex",
        meta: { requiresAuth: false },
        component: () =>
            import("../views/instruments/VendorsSalesSlip/VendorSalesSlipForm/VendorSalesSlipForm.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/vendor-sales-slips/new",
        name: "Create Vendor Sales Slip",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/VendorsSalesSlip/VendorSalesSlipForm/VendorSalesSlipForm.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/vendor-sales-slips/:profileHex/edit/:id',
        name: 'Edit Vendor Sales Slip',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/VendorsSalesSlip/VendorSalesSlipForm/VendorSalesSlipForm.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/vendor-sales-slips/:profileHex/edit/:id',
        name: 'Edit Vendor Sales Slip',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/VendorsSalesSlip/VendorSalesSlipForm/VendorSalesSlipForm.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/vendor-sales-slips/:profileHex/new/:id/success',
        name: 'Vendor Sales Slip Created',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/VendorsSalesSlip/VendorSalesSlipForm/VendorSalesSlipNewSuccess.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/central-terminal-sales-form/:hexCode",
        name: "Create Central Terminal Sales",
        meta: { requiresAuth: false },
        component: () =>
            import("../views/instruments/CentralTerminalSales/CentralTerminalSalesForm/CentralTerminalSalesForm.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/central-terminal-sales/new",
        name: "Create Central Terminal Sales",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/CentralTerminalSales/CentralTerminalSalesForm/CentralTerminalSalesForm.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/central-terminal-sales/:profileHex/edit/:id',
        name: 'Edit Central Terminal Sales',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/CentralTerminalSales/CentralTerminalSalesForm/CentralTerminalSalesForm.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/central-terminal-sales/:profileHex/edit/:id',
        name: 'Edit Central Terminal Sales',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/CentralTerminalSales/CentralTerminalSalesForm/CentralTerminalSalesForm.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/central-terminal-sales/:profileHex/new/:id/success',
        name: 'Central Terminal Sales Created',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/CentralTerminalSales/CentralTerminalSalesForm/CentralTerminalSalesNewSuccess.vue")
        }
    },

    {
        path: process.env.VUE_APP_BASE_PATH + "/central-terminal-tokens-form/:hexCode",
        name: "Create Central Terminal Tokens",
        meta: { requiresAuth: false },
        component: () =>
            import("../views/instruments/CentralTerminalTokens/CentralTerminalTokensForm/CentralTerminalTokensForm.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/central-terminal-tokens/new",
        name: "Create Central Terminal Tokens",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/CentralTerminalTokens/CentralTerminalTokensForm/CentralTerminalTokensForm.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/central-terminal-tokens/:profileHex/edit/:id',
        name: 'Edit Central Terminal Tokens',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/CentralTerminalTokens/CentralTerminalTokensForm/CentralTerminalTokensForm.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/central-terminal-tokens/:profileHex/edit/:id',
        name: 'Edit Central Terminal Tokens',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/CentralTerminalTokens/CentralTerminalTokensForm/CentralTerminalTokensForm.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/central-terminal-tokens/:profileHex/new/:id/success',
        name: 'Central Terminal Tokens Created',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/CentralTerminalTokens/CentralTerminalTokensForm/CentralTerminalTokensNewSuccess.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/market-programs/new",
        name: "Create Market Program",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/MarketProgram/MarketProgramForm/MarketProgramForm.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/market-programs",
        name: "Market Programs",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/MarketProgram/MarketProgramData/MarketProgramData.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/market-programs/:profileHex/edit/:id',
        name: 'Edit Market Program',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/MarketProgram/MarketProgramForm/MarketProgramForm.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/ecosystem-services-form',
        name: 'Ecosystem Services Form',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/EcosystemServices/ESSForm/ESSForm.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/download-documents',
        name: 'Download Documents',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/DownloadDocuments/DownloadDocuments.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/member-resources',
        name: 'Member Resources',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/MemberResources/MemberResources.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/ecosystem-services',
        name: 'Ecosystem Services',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/EcosystemServices/ESSLanding/ESSLanding.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/profiles',
        name: 'Market Profile',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/profiles/MarketProfile/MarketProfile.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/producer-profiles',
        name: 'Producer Profile',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/profiles/ProducerProfile/ProducerProfile.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/org-profiles',
        name: 'Market Org Profile',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/profiles/MarketOrgProfile/MarketOrgProfile.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/org-account',
        name: 'Market Org Account',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/MarketOrg/MarketOrgAccount/MarketOrgAccount.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/order_form',
        name: 'Order Form',
        meta: { requiresAuth: false },
        component: function() {
            return import("../views/users/OrderForm/OrderForm.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/order_form_submit',
        name: 'Order Form Submit',
        meta: { requiresAuth: false },
        component: function() {
            return import("../views/users/OrderFormSubmit/OrderFormSubmit.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/ecosystem-services/:id/edit',
        name: 'Ecosystem Services Edit',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/EcosystemServices/ESSForm/ESSForm.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/ecosystem-services/results-producer',
        name: 'Ecosystem Services Producer Results',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/EcosystemServices/ResultsProducer/ResultsProducer.vue")
        }
    },

    {
        path: process.env.VUE_APP_BASE_PATH + '/ecosystem-services/results-market',
        name: 'Ecosystem Services Market Results',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/EcosystemServices/ResultsMarket/ResultsMarket.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/ecosystem-services/results-market-org',
        name: 'Ecosystem Services Market Org Results',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments_MarketOrgs/EcosystemServices/ResultsMarketOrg/ResultsMarketOrg.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + '/market-programs/:profileHex/new/:id/success',
        name: 'Market Program Created',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/MarketProgram/MarketProgramForm/MarketProgramNewSuccess.vue")
        }
    },

    {
        path: process.env.VUE_APP_BASE_PATH + '/market-programs/:profileHex/new/:id/success',
        name: 'Market Program Created',
        meta: { requiresAuth: true },
        component: function() {
            return import("../views/instruments/MarketProgram/MarketProgramForm/MarketProgramNewSuccess.vue")
        }
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/central-terminal-sales",
        name: "Central Terminal Sales",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/CentralTerminalSales/CentralTerminalSalesData/CentralTerminalSalesData.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/central-terminal-sales/upload",
        name: "Upload Central Terminal Sales",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/CentralTerminalSales/CentralTerminalSalesUpload/CentralTerminalSalesUpload.vue")
    },

    {
        path: process.env.VUE_APP_BASE_PATH + "/vendor-attendance/upload",
        name: "Upload Vendor Attendance",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/VendorAttendance/VendorAttendanceUpload/VendorAttendanceUpload.vue")
    },

    {
        path: process.env.VUE_APP_BASE_PATH + "/central-terminal-tokens",
        name: "Central Terminal Tokens",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/CentralTerminalTokens/CentralTerminalTokensData/CentralTerminalTokensData.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/central-terminal-tokens/upload",
        name: "Upload Central Terminal Tokens",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/CentralTerminalTokens/CentralTerminalTokensUpload/CentralTerminalTokensUpload.vue")
    },

    {
        path: process.env.VUE_APP_BASE_PATH + "/vendor-sales-slips",
        name: "Vendor Sales Slip Data",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/VendorsSalesSlip/VendorSalesSlipData/VendorSalesSlipData.vue")
    },

    {
        path: process.env.VUE_APP_BASE_PATH + "/vendor-attendance",
        name: "Vendor Attendance Data",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/VendorAttendance/VendorAttendanceData/VendorAttendanceData.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/vendor-sales-slips/upload",
        name: "Upload Vendor Sales Slips",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/VendorsSalesSlip/VendorSalesSlipUpload/VendorSalesSlipUpload.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/org-vendor-sales-slips",
        name: "Vendor Sales Slips Data",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments_MarketOrgs/VendorSalesSlipData/VendorSalesSlipData.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/org-vendor-applications",
        name: "Vendor Applications Data",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments_MarketOrgs/VendorApplicationData/VendorApplicationData.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/org-vendor-attendance",
        name: "Vendor Attendance Data",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments_MarketOrgs/VendorAttendanceData/VendorAttendanceData.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/visitor-count",
        name: "Visitor Count Data",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/VisitorCount/VisitorCountData/VisitorCountData.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/visitor-count/upload",
        name: "Upload Visitor Counts",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/VisitorCount/VisitorCountUpload/VisitorCountUpload.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/org-visitor-count",
        name: "Visitor Count Org Data",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments_MarketOrgs/VisitorCountData/VisitorCountData.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/vendor-applications",
        name: "Vendor Application Data",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/VendorApplication/VendorApplicationData/VendorApplicationData.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/vendor-applications/edit/:id",
        name: "Edit Vendor Application",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/VendorApplication/VendorApplicationForm/VendorApplicationForm.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/vendor-applications/upload",
        name: "Upload Vendor Applications",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/VendorApplication/VendorApplicationUpload/VendorApplicationUpload.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/org-visitor-count",
        name: "Visitor Count Org Data",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments_MarketOrgs/VisitorCountData/VisitorCountData.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/org-central-terminal-sales",
        name: "Central Terminal Sales Data",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments_MarketOrgs/CentralTerminalSalesData/CentralTerminalSalesData.vue")
    },

    {
        path: process.env.VUE_APP_BASE_PATH + "/org-central-terminal-tokens",
        name: "Central Terminal Tokens Data",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments_MarketOrgs/CentralTerminalTokensData/CentralTerminalTokensData.vue")
    },


    {
        path: process.env.VUE_APP_BASE_PATH + "/visitor-surveys/upload",
        name: "Upload Visitor Surveys",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/VisitorSurvey/VisitorSurveyUpload/VisitorSurveyUpload.vue")
    },

    {
        path: process.env.VUE_APP_BASE_PATH + "/org-market-programs",
        name: "Market Program Data",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments_MarketOrgs/MarketProgramData/MarketProgramData.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/market-programs/upload",
        name: "Upload Market Programs",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments/MarketProgram/MarketProgramUpload/MarketProgramUpload.vue")
    },

    {
        path: process.env.VUE_APP_BASE_PATH + "/org-visitor-surveys",
        name: "Visitor Survey Data",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/instruments_MarketOrgs/VisitorSurveyData/VisitorSurveyData.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/infographics/",
        name: "Infographics",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/analysis/InfographicsView/InfographicsView.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/data-analysis/:profile_id?/:instrument?",
        name: "Data Analysis",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/analysis/DataAnalysis/DataAnalysis.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/graphs/",
        name: "Graphs",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/analysis/GraphsView/GraphsView.vue")
    },
    {
        path: process.env.VUE_APP_BASE_PATH + "/select-metrics",
        name: "SelectMetrics",
        meta: { requiresAuth: true },
        component: () =>
            import("../views/SelectMetrics/SelectMetrics.vue")
    },
];

const router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {

    const userInfoStore = useUserInfoStore()
    const loggedIn = !!userInfoStore.currentUserId

    document.title = to.meta.title || 'Farm2Facts';

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!loggedIn) {
            next({
                path: process.env.VUE_APP_BASE_PATH + '/login'
            })
        } else {
            if (userInfoStore.orgType === "Individual Producer") {
                if (to.path === process.env.VUE_APP_BASE_PATH + "/welcome") {
                    next({
                        path: process.env.VUE_APP_BASE_PATH + "/welcome-producer"
                    })
                }
                else if (to.path === process.env.VUE_APP_BASE_PATH +  "/profiles") {
                    next({
                        path: process.env.VUE_APP_BASE_PATH + "/producer-profiles"
                    })
                }
                else if (to.path === process.env.VUE_APP_BASE_PATH + "/") {
                    next({
                        path: process.env.VUE_APP_BASE_PATH + "/ecosystem-services"
                    })
                }
                else if (to.path === process.env.VUE_APP_BASE_PATH ) {
                    next({
                        path: process.env.VUE_APP_BASE_PATH + "/ecosystem-services"
                    })
                } else {
                    next()
                }
            }
            else if (userInfoStore.orgType === "Market Organization") {
                if (to.path === process.env.VUE_APP_BASE_PATH + "/profiles") {
                    next({
                        path: process.env.VUE_APP_BASE_PATH + "/org-profiles"
                    })
                } else if (to.path === process.env.VUE_APP_BASE_PATH + "/visitor-surveys") {
                    next({
                        path: process.env.VUE_APP_BASE_PATH + "/org-visitor-surveys"
                    })
                } else if (to.path === process.env.VUE_APP_BASE_PATH + "/vendor-applications") {
                    next({
                        path: process.env.VUE_APP_BASE_PATH + "/org-vendor-applications"
                    })
                }
                else if (to.path === process.env.VUE_APP_BASE_PATH + "/visitor-count") {
                    next({
                        path: process.env.VUE_APP_BASE_PATH + "/org-visitor-count"
                    })
                }
                else if (to.path === process.env.VUE_APP_BASE_PATH + "/market-programs") {
                    next({
                        path: process.env.VUE_APP_BASE_PATH + "/org-market-programs"
                    })
                }
                else if (to.path === process.env.VUE_APP_BASE_PATH + "/central-terminal-sales") {
                    next({
                        path: process.env.VUE_APP_BASE_PATH + "/org-central-terminal-sales"
                    })
                }
                else if (to.path === process.env.VUE_APP_BASE_PATH + "/ecosystem-services") {
                    next({
                        path: process.env.VUE_APP_BASE_PATH + "/ecosystem-services/results-market-org"
                    })
                }
                else if (to.path === process.env.VUE_APP_BASE_PATH + "/central-terminal-tokens") {
                    next({
                        path: process.env.VUE_APP_BASE_PATH + "/org-central-terminal-tokens"
                    })
                }
                else if (to.path === process.env.VUE_APP_BASE_PATH + "/vendor-attendance") {
                    next({
                        path: process.env.VUE_APP_BASE_PATH + "/org-vendor-attendance"
                    })
                } else if (to.path === process.env.VUE_APP_BASE_PATH + "/volunteers") {
                    next({
                        path: process.env.VUE_APP_BASE_PATH + "/org-volunteers"
                    })
                } else {
                    next()
                }

            } else {
                next()
            }
        }
    } else {
        next()
    }


})

export default router;
