<template>
  <MDBFooter bg="dark" :text="['center', 'white']">

    <br/>
    <div id="affiliations">Contact us at <a href="mailto:info@farm2facts.org">info@farm2facts.org</a>.</div>

    <div class="footer-nav-container">
      <div class="social-media-container">
          <a href='https://www.facebook.com/farm2facts/' target='_blank' class="social-media-icon">
            <i class="fa-brands fa-facebook" style="font-size:40px; color:white; margin-right:20px"></i>
          </a>
        <a href="http://www.linkedin.com/company/farm2facts/" target="_blank" class="social-media-icon">
          <i class="fa-brands fa-linkedin" style="font-size:40px; color:white; margin-right:20px"></i>
        </a>
      </div>
    </div>
    <br/>
    <div class="footer-crest-container">
      <div class="footer-uw-crest" >
        <a href="http://www.wisc.edu/" target="_blank"><img src="https://account.farm2facts.org/assets/logo-uw-white.png" style="height:90px"/></a>
      </div>
      <div class="footer-uw-crest" >
        <a href="https://extension.wisc.edu/" target="_blank"><img src="https://account.farm2facts.org/assets/EXT_color-center-reverse.png" style="height:100px"/></a>
      </div>
      <br/>
      <div id="affiliations" style="font-size:14px;">Developed & operated in collaboration with University of Wisconsin-Madison</div>
    </div>
    <div id="affiliations"><p style="font-size:14px;">Affiliated entities include the <a href="https://dpla.wisc.edu/kaufmanlab" target="_blank"> Kaufman Lab for Food Systems and Marketplaces</a></p></div>

    <!-- Copyright -->
    <div
        class="text-center p-4"
        style="background-color: rgba(0, 0, 0, 0.05); font-size: 12px;"
    >
      Copyright &copy; 2024 <a href="https://www.wisconsin.edu/regents/" target="_blank">The Board of Regents of the University of Wisconsin System</a>
    </div>

</MDBFooter>
  <!-- Global site tag (gtag.js) - Google Analytics -->

  <!--<script async src="https://www.googletagmanager.com/gtag/js?id=UA-146616978-1"></script>

  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'UA-146616978-1');
  </script>-->

</template>

<script>
  import { MDBFooter } from 'mdb-vue-ui-kit';
    export default {
        name: 'footerDiv',
        components: {
          MDBFooter,
        }
    };
</script>
