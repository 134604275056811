<template>
  <MDBNavbar expand="lg" dark bg="dark" position="top" container>
    <MDBNavbarBrand :href="basePath + '/'"><a :href="basePath + '/'"><img
        src="https://account.farm2facts.org/assets/farm2facts_logo_horizontal_yellow_light.png"
        height="100"
        alt=""
        loading="lazy"
        style="margin-top:-23px; margin-bottom:-25px"
    /></a></MDBNavbarBrand>
    <!-- Toggle button -->
    <MDBNavbarToggler
        target="#navbarRightAlignExample"
        @click="collapse5 = !collapse5"
    ></MDBNavbarToggler>
    <!-- Collapsible wrapper -->
    <MDBCollapse v-model="collapse5" id="navbarRightAlignExample">
      <MDBNavbarNav right class="mb-5 mb-lg-0">
        <!-- Navbar dropdown -->
        <MDBDropdown class="nav-item px-4" v-model="dropdown1" v-if="orgType!=='Researcher'">
          <MDBDropdownToggle
              tag="a"
              class="nav-link"
              @click="dropdown1 = !dropdown1"
              v-if="loggedIn"
          >My Farm2Facts</MDBDropdownToggle
          >
          <MDBDropdownMenu>
            <MDBDropdownItem v-if="orgType!=='Market Organization' && orgType!=='Individual Producer' && orgType!=='Researcher'"  :href="basePath + '/profiles'">Market Profiles</MDBDropdownItem>
            <!--<MDBDropdownItem v-if="orgType!=='Market Organization' && orgType!=='Individual Producer'" :href="basePath + '/account'">Market Account</MDBDropdownItem>-->
            <MDBDropdownItem v-if="orgType!=='Market Organization' && orgType!=='Individual Producer' && orgType!=='Researcher'"  :href="basePath + '/member-resources'">Member Resources</MDBDropdownItem>

            <MDBDropdownItem v-if="orgType==='Market Organization'"  :href="basePath + '/org-profiles'">Market Org Profiles</MDBDropdownItem>
            <MDBDropdownItem v-if="orgType==='Market Organization'"  :href="basePath + '/org-account'">Market Org Account</MDBDropdownItem>
            <MDBDropdownItem v-if="orgType==='Market Organization'" :href="basePath + '/member-resources'">Member Resources</MDBDropdownItem>

            <MDBDropdownItem v-if="orgType==='Individual Producer'" :href="basePath + '/producer-profiles'">Producer Profiles</MDBDropdownItem>

          </MDBDropdownMenu>
        </MDBDropdown>

        <MDBDropdown class="nav-item px-4" v-model="dropdown2" v-if="orgType!=='Individual Producer' && orgType!=='Researcher'">
          <MDBDropdownToggle
              tag="a"
              class="nav-link"
              @click="dropdown2 = !dropdown2"
              v-if="loggedIn"
          >Select Metrics</MDBDropdownToggle
          >
          <MDBDropdownMenu>
            <MDBDropdownItem v-if="orgType!=='Individual Producer' && orgType!=='Researcher'" :href="basePath + '/select-metrics'">Select Metrics</MDBDropdownItem>
            <MDBDropdownItem :href="basePath + '/download-documents'">Download Documents</MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>


        <MDBDropdown class="nav-item px-4" v-model="dropdown3" >
          <MDBDropdownToggle
              tag="a"
              class="nav-link"
              @click="dropdown3 = !dropdown3"
              v-if="loggedIn"
          >Collect Data</MDBDropdownToggle
          >
          <MDBDropdownMenu>
            <label v-if="instruments.includes('Vendor Application') && orgType!=='Market Organization' && orgType!=='Researcher' && orgType!=='Individual Producer'" style="margin-left:5px; font-weight:500; margin-top:10px">Pre-Season:</label>
            <MDBDropdownItem v-if="instruments.includes('Vendor Application') && orgType!=='Market Organization' && orgType!=='Researcher' && orgType!=='Individual Producer'" :href="basePath + '/vendor-applications'">Vendor Application</MDBDropdownItem>
            <hr v-if="instruments.includes('Vendor Application') && orgType!=='Market Organization' && orgType!=='Researcher' && orgType!=='Individual Producer'" />
            <label v-if="orgType!=='Market Organization' && orgType!=='Researcher' && orgType!=='Individual Producer'" style="margin-left:5px; font-weight:500">During-Season:</label>
            <MDBDropdownItem v-if="instruments.includes('Visitor Count') && orgType!=='Market Organization' && orgType!=='Researcher' && orgType!=='Individual Producer'" :href="basePath + '/visitor-count'">Visitor Count</MDBDropdownItem>
            <MDBDropdownItem v-if="instruments.includes('Visitor Survey') && orgType!=='Market Organization' && orgType!=='Researcher' && orgType!=='Individual Producer'" :href="basePath + '/visitor-surveys'">Visitor Survey</MDBDropdownItem>
            <MDBDropdownItem v-if="instruments.includes('Vendor Sales Slips and Central Terminal Sales & Tokens') && orgType!=='Market Organization' && orgType!=='Researcher' && orgType!=='Individual Producer'" :href="basePath + '/vendor-sales-slips'">Vendor Sales Slip</MDBDropdownItem>
            <MDBDropdownItem v-if="instruments.includes('Vendor Sales Slips and Central Terminal Sales & Tokens') && orgType!=='Market Organization' && orgType!=='Researcher' && orgType!=='Individual Producer'" :href="basePath + '/central-terminal-sales'">Central Terminal Sales</MDBDropdownItem>
            <MDBDropdownItem v-if="instruments.includes('Vendor Sales Slips and Central Terminal Sales & Tokens') && orgType!=='Market Organization' && orgType!=='Researcher' && orgType!=='Individual Producer'" :href="basePath + '/central-terminal-tokens'">Central Terminal Tokens</MDBDropdownItem>
            <MDBDropdownItem v-if="instruments.includes('Vendor Attendance') && orgType!=='Market Organization' && orgType!=='Researcher' && orgType!=='Individual Producer'" :href="basePath + '/vendor-attendance'">Vendor Attendance</MDBDropdownItem>
            <MDBDropdownItem v-if="instruments.includes('Volunteers') && orgType!=='Market Organization' && orgType!=='Researcher' && orgType!=='Individual Producer'" :href="basePath + '/volunteers'">Volunteers</MDBDropdownItem>
            <MDBDropdownItem v-if="instruments.includes('Market Programs') && orgType!=='Market Organization' && orgType!=='Researcher' && orgType!=='Individual Producer'" :href="basePath + '/market-programs'">Market Programs</MDBDropdownItem>
            <MDBDropdownItem v-if="instruments.includes('Ecosystem Services') && orgType!=='Market Organization' && orgType!=='Researcher' && orgType!=='Individual Producer'" :href="basePath + '/ecosystem-services/results-market'">Ecosystem Services</MDBDropdownItem>

            <label v-if="instruments.includes('Vendor Application') && orgType==='Market Organization' || orgType==='Researcher'" style="margin-left:5px; font-weight:500; margin-top:10px">Pre-Season:</label>
            <MDBDropdownItem v-if="instruments.includes('Vendor Application') && orgType==='Market Organization' || orgType==='Researcher'" :href="basePath + '/org-vendor-applications'">Vendor Application</MDBDropdownItem>
            <hr  v-if="instruments.includes('Vendor Application') && orgType==='Market Organization' || orgType==='Researcher'"/>
            <label v-if="orgType==='Market Organization' || orgType==='Researcher'" style="margin-left:5px; font-weight:500">During-Season:</label>
            <MDBDropdownItem  v-if="instruments.includes('Visitor Count') && orgType==='Market Organization' || orgType==='Researcher'" :href="basePath + '/org-visitor-count'">Visitor Count</MDBDropdownItem>
            <MDBDropdownItem  v-if="instruments.includes('Visitor Survey') && orgType==='Market Organization' || orgType==='Researcher'" :href="basePath + '/org-visitor-surveys'">Visitor Survey</MDBDropdownItem>
            <MDBDropdownItem v-if="instruments.includes('Vendor Sales Slips and Central Terminal Sales & Tokens') && orgType==='Market Organization' || orgType==='Researcher'" :href="basePath + '/org-vendor-sales-slips'">Vendor Sales Slip</MDBDropdownItem>
            <MDBDropdownItem  v-if="instruments.includes('Vendor Sales Slips and Central Terminal Sales & Tokens') && orgType==='Market Organization' || orgType==='Researcher'" :href="basePath + '/org-central-terminal-sales'">Central Terminal Sales</MDBDropdownItem>
            <MDBDropdownItem  v-if="instruments.includes('Vendor Sales Slips and Central Terminal Sales & Tokens') && orgType==='Market Organization' || orgType==='Researcher'" :href="basePath + '/org-central-terminal-tokens'">Central Terminal Tokens</MDBDropdownItem>
            <MDBDropdownItem  v-if="orgType==='Individual Producer' && showProducerVendorSalesSlips" :href="basePath + '/vendor-sales-slips-producer'">Vendor Sales Slips</MDBDropdownItem>


            <MDBDropdownItem  v-if="instruments.includes('Vendor Attendance') && orgType==='Market Organization' || orgType==='Researcher'" :href="basePath + '/org-vendor-attendance'">Vendor Attendance</MDBDropdownItem>
            <MDBDropdownItem  v-if="instruments.includes('Volunteers') && orgType==='Market Organization' || orgType==='Researcher'" :href="basePath + '/org-volunteers'">Volunteers</MDBDropdownItem>
            <MDBDropdownItem  v-if="instruments.includes('Market Programs') && orgType==='Market Organization' || orgType==='Researcher'" :href="basePath + '/org-market-programs'">Market Programs</MDBDropdownItem>
            <MDBDropdownItem  v-if="instruments.includes('Ecosystem Services') && orgType==='Market Organization' || orgType==='Researcher'" :href="basePath + '/ecosystem-services/results-market-org'">Ecosystem Services</MDBDropdownItem>


            <MDBDropdownItem  v-if="orgType==='Individual Producer'" :href="basePath + '/ecosystem-services-form'">Ecosystem Services</MDBDropdownItem>
            <hr v-if="orgType !== 'Individual Producer'" />
            <MDBDropdownItem  v-if="orgType !== 'Individual Producer'"  :href="basePath + '/select-metrics'">(Select Metrics to Adjust)</MDBDropdownItem>

          </MDBDropdownMenu>
        </MDBDropdown>


        <MDBDropdown class="nav-item px-4" v-model="dropdown4"  >
          <MDBDropdownToggle
              tag="a"
              class="nav-link"
              @click="dropdown4 = !dropdown4"
              v-if="loggedIn"
          >Communicate Findings</MDBDropdownToggle>
          <MDBDropdownMenu>
            <MDBDropdownItem v-if="loggedIn && orgType!=='Individual Producer'" :href="basePath + '/data-analysis'">Analyze Data</MDBDropdownItem>
            <MDBDropdownItem v-if="loggedIn && orgType!=='Individual Producer'" :href="basePath + '/graphs'">Visualize Data</MDBDropdownItem>

            <MDBDropdownItem v-if="loggedIn && orgType==='Individual Producer'" :href="basePath + '/ecosystem-services/results-producer'">Ecosystem Services Results</MDBDropdownItem>
            <MDBDropdownItem v-if="loggedIn" :href="basePath + '/infographics'">Build Infographics</MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>

        <MDBDropdown v-if="admin" class="nav-item px-4" v-model="dropdown5">
          <MDBDropdownToggle
              tag="a"
              class="nav-link"
              @click="dropdown5 = !dropdown5"
          >Admin</MDBDropdownToggle
          >
          <MDBDropdownMenu>
            <MDBDropdownItem v-if="loggedIn" :href="basePath + '/users'">User Accounts</MDBDropdownItem>
            <MDBDropdownItem v-if="loggedIn" :href="basePath + '/export-data'">Export Data</MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>

        <!-- Right links -->
        <MDBNavbarItem v-if="loggedIn" href="#" @click="logout">
          Logout
        </MDBNavbarItem>

        <MDBNavbarItem v-if="!loggedIn"  :href="basePath + '/register'" >
          Register
        </MDBNavbarItem>

        <MDBNavbarItem v-if="!loggedIn"  :href="basePath + '/login'" inactive >
          Log in
        </MDBNavbarItem>

      </MDBNavbarNav>
    </MDBCollapse>
    <!-- Collapsible wrapper -->
  </MDBNavbar>

  <Masquerade v-if="userInfoStore.masquerading"></Masquerade>

  <MDBAlert
      v-model="alert"
      color="warning"
      position="bottom-left"
      width="98%"
      dismiss
      :autohide="false"
      stacking
  >
    <div v-html="alertMessage" />
  </MDBAlert>
</template>


<script src="./_MainMenu.js" lang="js"></script>
<style src="./_MainMenu.less" lang="less" scoped></style>
