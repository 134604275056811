<template>
    <div class="Masquerade sticky-lg-top">
      <i class="fas fa-exclamation-triangle me-3"></i><span style='font-weight:500'>You are currently masquerading as {{ this.userInfoStore.name }} (User ID {{ this.userInfoStore.currentUserId }}).    To stop masquerading, click <a :href="basePath + '/masquerade/0'" class="alert-link">here</a>.</span>
    </div>
</template>

<script>
  import {useUserInfoStore} from "@/stores/userInfo";


    export default {
        name: 'MasqueradeView',

      setup() {

        const userInfoStore = useUserInfoStore()
        const basePath = process.env.VUE_APP_BASE_PATH;

        return {userInfoStore, basePath}
      }
    };
</script>

<style>
.Masquerade {
  background-color: #faecd1; /*rgb(255 246 160);*/ /*#faecd1;*/
  color: #73510d;
  border-bottom: 1px solid rgba(0,0,0,.3);
  height:45px;
  padding-top:13px;
}
</style>
